<template>
  <header :class="`header ${navclass} ${headerclass}`">
    <div class="container">
      <div class="header__social">
        <a href="https://www.facebook.com/umft.uz" target="_blank"
          ><img src="@/assets/icons/facebook.svg" alt=""
        /></a>
        <a href="https://www.instagram.com/umft.uz/" target="_blank"
          ><img src="@/assets/icons/inst.svg" alt=""
        /></a>
        <a href="https://t.me/umftuz" target="_blank"
          ><img src="@/assets/icons/tg.svg" alt=""
        /></a>
      </div>
      <a href="https://t.me/UMFT_UniversityBot" target="_blank" class="ml-auto">
        <img src="@/assets/icons/support.svg" alt="" />
        @umft_support
      </a>
      <a href="mailto:info@umft.uz" target="_blank" class="ml-40">
        <img src="@/assets/icons/email.svg" alt="" />
        info@umft.uz
      </a>
      <a href="tel:998555068888" class="ml-40">
        <img src="@/assets/icons/phone.svg" alt="" />
        (55) 506 88 88
      </a>
      <a href="tel:998555208888" class="ml-40">
        <img src="@/assets/icons/phone.svg" alt="" />
        (55) 520 88 88
      </a>
      <a
        href="https://yandex.uz/maps/-/CCUbqIwrDC"
        target="_blank"
        class="ml-40"
      >
        <img src="@/assets/icons/location.svg" alt="" />
        {{ $t("header.address") }}
      </a>
      <div class="lang">
        <div class="lang--current">
          <img :src="require(`@/assets/flags/${locale}.svg`)" alt="" />
          {{ titleLocale[locale] }} <el-icon><arrow-down /></el-icon>
        </div>
        <div class="lang--list">
          <span
            v-for="(lang, langIndex) of listLocale"
            :key="langIndex"
            @click="changeLang(lang)"
          >
            <img :src="require(`@/assets/flags/${lang}.svg`)" alt="" />
            {{ titleLocale[lang] }}
          </span>
        </div>
      </div>
    </div>
  </header>
  <nav :class="`nav ${navclass} ${headerclass}`" v-show="headerBtn">
    <div class="container">
      <router-link to="/" class="nav__logo">
        <img src="@/assets/logo.svg" alt="" />
        <span> University of Management and Future Technologies </span>
      </router-link>

      <ul class="nav__menu">
        <li class="nav__drop">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link nav__dir">
              {{ $t("menu.about") }}
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toUrl('/about')">
                  {{ $t("menu.about") }}
                </el-dropdown-item>
                <el-dropdown-item @click="toUrl('/?to=campus')">
                  {{ $t("menu.campus") }}
                </el-dropdown-item>
                
     
                <el-dropdown-item @click="toUrl('/gallery')">{{
                  $t("menu.gallery")
                }}</el-dropdown-item>
                <el-dropdown-item>{{ $t("menu.journal") }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li class="nav__drop">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link nav__dir">
              Tuzilma
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toUrl('/rektorat')">
                  Rahbariyat
                </el-dropdown-item>
                <el-dropdown-item @click="toUrl('/faculty')">
                  Fakultetlar
                </el-dropdown-item>
                <el-dropdown-item @click="toUrl('/kafedra')">
                  Kafedralar
                </el-dropdown-item>
                <el-dropdown-item @click="toUrl('/bolim')">
                  Bo'limlar
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li class="nav__drop">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link nav__dir">
              {{ $t("menu.faculty") }}
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
              <div class="el-dropdown-multi">
                <el-dropdown-menu
                  v-for="faculty of facultys"
                  :key="faculty._id"
                >
                  <el-dropdown-item
                    @click="toUrl(`/faculty/${faculty.slogan}`)"
                    class="el-dropdown-faculty"
                  >
                    {{
                      locale == "uz"
                        ? faculty.title
                        : faculty.translate.find((t) => t.language == locale)
                        ? faculty.translate.find((t) => t.language == locale)
                            .title
                        : faculty.title
                    }}
                  </el-dropdown-item>
                  <el-dropdown-item class="el-dropdown-study">
                    {{ $t("menu.bakalavr") }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    class="el-dropdown-direction"
                    :key="direc._id"
                    @click="toUrl(`/direction/${direc.slogan}`)"
                    v-for="direc of faculty.directions.filter(
                      (d) => d.study == 0
                    )"
                  >
                    {{
                      locale == "uz"
                        ? direc.title
                        : direc.translate.find((t) => t.language == locale)
                        ? direc.translate.find((t) => t.language == locale)
                            .title
                        : direc.title
                    }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    class="el-dropdown-study"
                    v-if="
                      faculty.directions.filter((d) => d.study == 1)?.length > 0
                    "
                  >
                    {{ $t("menu.magistr") }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    class="el-dropdown-direction"
                    :key="direc._id"
                    @click="toUrl(`/direction/${direc.slogan}`)"
                    v-for="direc of faculty.directions.filter(
                      (d) => d.study == 1
                    )"
                  >
                    {{
                      locale == "uz"
                        ? direc.title
                        : direc.translate.find((t) => t.language == locale)
                        ? direc.translate.find((t) => t.language == locale)
                            .title
                        : direc.title
                    }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </div>
            </template>
          </el-dropdown>
          <div
            :class="`nav__dropdown nav__fac ${dropmenu == 1 ? 'active' : ''}`"
          >
            <ul>
              <li class="nav__dir">{{ $t("menu.bakalavr") }}</li>
              <li v-for="direction of bak" :key="direction._id">
                <span @click="toUrl(`/direction/${direction.slogan}`)">{{
                  locale == "uz"
                    ? direction.title
                    : direction.translate.find((t) => t.language == locale)
                    ? direction.translate.find((t) => t.language == locale)
                        .title
                    : direction.title
                }}</span>
              </li>
            </ul>
            <ul>
              <li class="nav__dir">{{ $t("menu.magistr") }}</li>
              <li v-for="direction of mag" :key="direction._id">
                <span @click="toUrl(`/direction/${direction.slogan}`)">{{
                  locale == "uz"
                    ? direction.title
                    : direction.translate.find((t) => t.language == locale)
                    ? direction.translate.find((t) => t.language == locale)
                        .title
                    : direction.title
                }}</span>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav__drop">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link nav__dir">
              {{ $t("menu.admis") }}
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toUrl('/?to=direction')">
                  {{ $t("menu.direction") }}
                </el-dropdown-item>
                <el-dropdown-item @click="toUrl('/condition')">
                  {{ $t("menu.condition") }}
                </el-dropdown-item>
                <el-dropdown-item @click="toUrl('/admission')">{{
                  $t("menu.admission")
                }}</el-dropdown-item>
                <el-dropdown-item @click="toUrl('/relocation')">{{
                  $t("menu.relocation")
                }}</el-dropdown-item>
                <el-dropdown-item @click="toUrl('/contract')">{{
                  $t("menu.contract")
                }}</el-dropdown-item>

                <el-dropdown-item @click="toUrl('/vaucher')">{{
                  $t("menu.vaucher")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <span @click="toUrl('/contact')">{{ $t("menu.contact") }}</span>
        </li>
        <li>
          <span @click="toUrl('/faq')">{{ $t("menu.faq") }}</span>
        </li>
      </ul>

      <div :class="`nav__mobile ${nav ? 'open' : ''}`">
        <button
          @click="toggle()"
          :class="`nav__close ${drop !== 0 ? 'open' : 'close'}`"
        >
          <img v-show="drop !== 0" src="@/assets/icons/left.svg" alt="" />
          <img v-show="drop == 0" src="@/assets/icons/close.svg" alt="" />
        </button>
        <ul class="nav__mobile--menu">
          <li class="nav__drop">
            <span class="nav__toggle" @click="drop = 1">{{
              $t("menu.about")
            }}</span>
            <ul :class="`nav__dropdown ${drop == 1 ? 'active' : ''}`">
              <li>
                <span @click="toUrl('/about')">{{ $t("menu.univer") }}</span>
              </li>
              <li>
                <span @click="toUrl('/?to=campus')">{{
                  $t("menu.campus")
                }}</span>
              </li>
              

              <li>
                <span @click="toUrl('/gallery')">{{ $t("menu.gallery") }}</span>
              </li>

              <li>
                <a href="https://journal.umft.uz" target="_blank">{{
                  $t("menu.journal")
                }}</a>
              </li>
            </ul>
          </li>
          <li class="nav__drop">
            <span class="nav__toggle" @click="drop = 4"> Tuzilma </span>
            <ul :class="`nav__dropdown ${drop == 4 ? 'active' : ''}`">
              <li>
                <span @click="toUrl('/rektorat')"> Rahbariyat </span>
              </li>
              <li>
                <span @click="toUrl('/faculty')"> Fakultetlar </span>
              </li>
              <li>
                <span @click="toUrl('/kafedra')" to="/"> Kafedralar </span>
              </li>
              <li>
                <span @click="toUrl('/bolim')" to="/"> Bo'limlar </span>
              </li>
            </ul>
          </li>
          <li class="nav__drop">
            <span class="nav__toggle" @click="drop = 2">{{
              $t("menu.faculty")
            }}</span>
            <div
              :class="`nav__dropdown nav__faculties ${
                drop == 2 ? 'active' : ''
              }`"
            >
              <ul v-for="faculty of facultys" :key="faculty._id">
                <li class="nav__faculty">
                  <span @click="toUrl(`/faculty/${faculty.slogan}`)">
                    {{
                      locale == "uz"
                        ? faculty.title
                        : faculty.translate.find((t) => t.language == locale)
                        ? faculty.translate.find((t) => t.language == locale)
                            .title
                        : faculty.title
                    }}
                  </span>
                </li>
                <li class="nav__dir">
                  <span>{{ $t("menu.bakalavr") }}</span>
                </li>
                <li
                  v-for="direction of faculty.directions.filter(
                    (d) => d.study == 0
                  )"
                  :key="direction._id"
                >
                  <span @click="toUrl(`/direction/${direction.slogan}`)">
                    {{
                      locale == "uz"
                        ? direction.title
                        : direction.translate.find((t) => t.language == locale)
                        ? direction.translate.find((t) => t.language == locale)
                            .title
                        : direction.title
                    }}</span
                  >
                </li>
                <li
                  class="nav__dir"
                  v-if="
                    faculty.directions.filter((d) => d.study == 1)?.length > 0
                  "
                >
                  <span>{{ $t("menu.magistr") }}</span>
                </li>
                <li
                  v-for="direction of faculty.directions.filter(
                    (d) => d.study == 1
                  )"
                  :key="direction._id"
                >
                  <span @click="toUrl(`/direction/${direction.slogan}`)">{{
                    locale == "uz"
                      ? direction.title
                      : direction.translate.find((t) => t.language == locale)
                      ? direction.translate.find((t) => t.language == locale)
                          .title
                      : direction.title
                  }}</span>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav__drop">
            <span class="nav__toggle" @click="drop = 3">{{
              $t("menu.admis")
            }}</span>
            <ul :class="`nav__dropdown ${drop == 3 ? 'active' : ''}`">
              <li>
                <span @click="toUrl('/?to=direction')">{{
                  $t("menu.direction")
                }}</span>
              </li>
              <li>
                <span @click="toUrl('/condition')" to="/">{{
                  $t("menu.condition")
                }}</span>
              </li>
              <li>
                <span @click="toUrl('/admission')" to="/">{{
                  $t("menu.admission")
                }}</span>
              </li>
              <li>
                <span @click="toUrl('/relocation')" to="/">{{
                  $t("menu.relocation")
                }}</span>
              </li>
              <li>
                <span @click="toUrl('/contract')" to="/">{{
                  $t("menu.contract")
                }}</span>
              </li>
              <li>
                <span @click="toUrl('/vaucher')" to="/">{{
                  $t("menu.vaucher")
                }}</span>
              </li>
            </ul>
          </li>
          <li>
            <span @click="toUrl('/contact')" class="link" to="/">{{
              $t("menu.contact")
            }}</span>
          </li>
          <li>
            <span @click="toUrl('/faq')" class="link" to="/">{{
              $t("menu.faq")
            }}</span>
          </li>
        </ul>
        <div class="nav__langs">
          <span
            v-for="(lang, langIndex) of allLocale"
            :key="langIndex"
            @click="changeLang(lang)"
            :class="`${lang == locale ? 'active' : ''}`"
          >
            <img :src="require(`@/assets/flags/${lang}.svg`)" alt="" />
          </span>
        </div>
        <span @click="toUrl('/reception')" class="btn">{{
          $t("nav.admit")
        }}</span>
      </div>
      <button class="nav__btn" @click="nav = true">
        <span></span>
      </button>
      <router-link to="/reception" class="btn">{{
        $t("nav.admit")
      }}</router-link>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["headerclass"],
  data() {
    return {
      dropmenu: -1,
      drop: 0,
      img: "",
      nav: false,
    };
  },
  computed: {
    ...mapGetters([
      "directions",
      "locale",
      "listLocale",
      "titleLocale",
      "allLocale",
      "headerBtn",
      "facultys",
    ]),
    mag() {
      return this.directions.filter((dir) => dir.study == 1);
    },
    bak() {
      return this.directions.filter((dir) => dir.study == 0);
    },
    navclass() {
      return this.$store.getters.navclass;
    },
  },
  methods: {
    ...mapMutations(["setLocale"]),
    toUrl(url) {
      this.nav = false;
      this.drop = 0;
      this.dropmenu = -1;
      this.$router.push(url);
    },
    menuToggle(val) {
      this.dropmenu = this.dropmenu === val ? -1 : val;
    },
    toggle() {
      if (this.drop === 0) {
        this.nav = false;
      } else {
        this.drop = 0;
      }
    },
    changeLang(lang) {
      this.setLocale(lang);
    },
  },
  mounted() {
    if (this.$cookies.isKey("umft-lang")) {
      this.setLocale(this.$cookies.get("umft-lang"));
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/components/header.scss";
</style>
