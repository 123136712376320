import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactPage.vue"),
  },
  // {
  //   path: "/grant",
  //   name: "grant",
  //   component: () => import("../views/GrantPage.vue"),
  // },
  {
    path: "/admit",
    name: "admit",
    component: () => import("../views/AdmitPage.vue"),
  },
  {
    path: "/reception",
    name: "reception",
    component: () => import("../views/ReceptionPage.vue"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/GalleryPage.vue"),
  },
  {
    path: "/directions",
    name: "directions",
    component: () => import("../views/DirectionsList.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/FaqList.vue"),
  },
  {
    path: "/reports/:id",
    name: "report",
    component: () => import("../views/ReportsView.vue"),
  },
  {
    path: "/result/:id",
    name: "result",
    component: () => import("../views/ResultPage.vue"),
  },
  {
    path: "/room/:id",
    name: "room",
    component: () => import("../views/RoomView.vue"),
  },
  {
    path: "/direction/:slogan",
    name: "direction",
    component: () => import("../views/DirectionPage.vue"),
  },
  {
    path: "/faculty/:slogan",
    name: "faculty",
    component: () => import("../views/FacultyPage.vue"),
  },
  {
    path: "/certificate/:id",
    name: "certificate",
    component: () => import("../views/CertPage.vue"),
  },
  {
    path: "/certificate/:id",
    name: "certificate",
    component: () => import("../views/CertPage.vue"),
  },
  {
    path: "/winners",
    name: "winners",
    component: () => import("../views/GrantResult.vue"),
  },
  {
    path: "/examresult",
    name: "magresult",
    component: () => import("../views/MagResult.vue"),
  },
  {
    path: "/winner/:id",
    name: "winner",
    component: () => import("../views/GrantWinner.vue"),
  },
  {
    path: "/rektorat",
    name: "rektorat",
    component: () => import("@/views/structure/bossPage.vue"),
  },
  {
    path: "/faculty",
    name: "facultypage",
    component: () => import("@/views/structure/facultyPage.vue"),
  },
  {
    path: "/kafedra",
    name: "kafedra",
    component: () => import("@/views/structure/kafedraPage.vue"),
  },
  {
    path: "/bolim",
    name: "bolim",
    component: () => import("@/views/structure/bolimPage.vue"),
  },
  {
    path: "/:slug",
    name: "page",
    component: () => import("../views/PageView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
